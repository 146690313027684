/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  line-height: $base-line-height * $base-font-size * 2.25;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.social-media-list {
  list-style: none;
  margin: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

.post-excerpt {
  flex: 1;
}

.post-read-more {
  @include relative-font-size(0.765);
  margin-top: 0;
  padding-top: 0;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($small-screen) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($small-screen) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    font-weight: 600;
    @include relative-font-size(1.25);

    @include media-query($small-screen) {
      @include relative-font-size(1.125);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($small-screen) {
      @include relative-font-size(1.125);
    }
  }
}

.about-header-image {
  display: block;
  margin: auto;
  width: 20rem;
  border-radius: 0.2rem;

  @include media-query($small-screen) {
    width: 100%;
    border-radius: 1%;
  }
}

.post-image {
  display: block;
  margin: auto;
  width: 40rem;
  border-radius: 0.2rem;

  @include media-query($small-screen) {
    width: 100%;
    border-radius: 1%;
  }
}

.post-image-label {
  display: block;
  text-align: center;
  font-style: italic;
}